import axios from 'axios';
import {Message} from 'element-ui'
import router from '@/router/index'
//axios.defaults.headers.common['token'] = store.state.token;
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;
// axios.defaults.baseURL = process.env.VUE_APP_BASEURL
axios.defaults.withCredentials = true; //让ajax携带cookie
// console.log(process.env.VUE_APP_BASEURL);
axios.defaults.baseURL = 'http://www.dhcmetis.com'
// axios.defaults.baseURL = 'http://production.dhcmetis.com'
// axios.defaults.baseURL = 'http://10.131.101.79:8081/cas/login?service=http%3A%2F%2F10.131.101.79%3A8081%2F%3Bjsessionid%3DEE66DA89388DD28DFFEAB4A8CDB3BD67%3Fts%3D1587956577761#/'; //袁鹏

import {localData} from "../utils/storage";
import globalConst from '../utils/globalConst'
import { Promise } from 'core-js';

axios.interceptors.request.use(
    config => {
        config.headers.common['token'] = getLocalToken()
        let requiredParam = {
            env: localData('get', globalConst.ENV, '', false) || 'test',
            researchCode: localData('get', globalConst.RESEARCH_CODE)|| '',
            versionCode: localData('get', globalConst.RESEARCH_VERSION_CODE, '', false)|| '',
        }
        config.params = Object.assign(requiredParam, config.params);
        return config;
    }, err => {
        return Promise.reject(err);
});

// 从localStorage中获取token
function getLocalToken () {
    return localStorage.getItem('token') || ''
}

// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
// axios.setToken = (token) => {
//     axios.defaults.headers.common['token'] = token
//     window.localStorage.setItem('token', token)
// }

// 获取新的token
function refreshToken(){
    // return axios.post('/api/base/forgetPwd',{
    //     "email":"1352309932323@163.com",
    //     "itcode":"shiyh"
    // }).then(res => res.data)
    return new Promise((resolve,reject)=>{
        resolve()
    })
}
  
// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []
// 响应拦截器
axios.interceptors.response.use(
 response => {
    const res = response.data

    if (res.code === 205 ) { // token 过期
        localStorage.setItem('invalid',1)
        if (!isRefreshing) {
            isRefreshing = true
            Message.error(res.msg);
            router.push({path:'/login'})
            return res
            
            
            //     // return axios(config)
                
            // }).catch((res)=>{
            //     localStorage.removeItem('token')
            //     window.location.href = '/'
            // }).finally(() => {
            //     isRefreshing = false
            // })

        } else {
            return Promise.reject('error')
        }
    } else if(res.code === 207 || res.code === 206){
        localStorage.setItem('passwordTimeOut',1)
        localStorage.setItem('dialogClose',0)
        localStorage.setItem('invalid',0)
        isRefreshing = false
        return res
    }else if(res.code === 209){
        isRefreshing = false
        localStorage.setItem('invalid',0)
        return res
    }
    else if(res.code === 200){
        isRefreshing = false
        localStorage.setItem('invalid',0)
        return res
    }
    else if(res.code === 210){
        isRefreshing = false
        localStorage.setItem('invalid',0)
        return res
    }
    else {
        isRefreshing = false
        localStorage.setItem('invalid',0)
        // localStorage.setItem('passwordTimeOut',0)
        // localStorage.setItem('dialogClose',1)
        Message({
            message: res.msg,
            type: 'error',
            duration: 2 * 1000
        })
        return Promise.reject('error')
    }
},
 err => {
    localStorage.setItem('invalid',0)
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                err.message = '错误请求';
                Message.error('http版本不支持该请求');
                break;
            case 403:
                err.message = '拒绝访问';
                Message.error('拒绝访问');
                break;
            case 404:
                err.message = '请求错误,未找到该资源';
                Message.error('请求错误,未找到该资源');
                break;
            case 405:
                err.message = '请求方法未允许';
                Message.error('请求方法未允许');
                break;
            case 408:
                err.message = '请求超时';
                Message.error('请求超时');
                break;
            case 500:
                err.message = '服务器端出错';
                Message.error('服务器端出错');
                break;
            case 501:
                err.message = '网络未实现';
                Message.error('网络未实现');
                break;
            case 502:
                err.message = '网络错误';
                Message.error('网络错误');
                break;
            case 503:
                err.message = '服务不可用';
                Message.error('服务不可用');
                break;
            case 504:
                err.message = '网络超时';
                Message.error('网络超时');
                break;
            case 505:
                err.message = 'http版本不支持该请求';
                Message.error('http版本不支持该请求');
                break;
            default:
                err.message = `连接错误${err.response.status}`
        }
    } else {
        err.message = "连接到服务器失败";
        Message.error('连接到服务器失败');
    }
    return Promise.reject(err)
});

export default axios;